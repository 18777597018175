<template>
  <CModal alignment="center" size="lg" :visible="visible"  @close="visible = false" backdrop="static">
    <slot></slot>
  </CModal>
</template>

<script>
export default {
  name: "BaseModal",
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      visible: false
    }
  },
  watch: {
    visible: function (val) {
      if (!val) {
        this.$emit('close')
        let element = document.querySelector("div.fade.modal-backdrop")
        console.log("backdrop element", element)
        if (element !== null) {
          let parent = element.parentNode
          console.log("parent element", parent)
          if (parent !== null) {
            element.classList.remove("show")
            element.style.visibility = 'hidden'
          }
        }
        element = document.querySelector("div.fade.modal")
        if (element !== null) {
          element.style.display = 'none'
        }
      }
    },
    isVisible: function (val) {
      this.visible = val
    }
  }
}
</script>

<style scoped>

</style>
