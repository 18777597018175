<template>
  <CMultiSelect
    :multiple="false"
    placeholder="Начните вводить..."
    options-style="text"
    :search="true"
    @change="selectItem"
  />
</template>

<script>
export default {
  name: "BaseSelect",
  emits:["input"],
  methods:{
  selectItem(item) {

    this.$emit("input", item[0].value)
    let elements = document.querySelectorAll("div.form-multi-select")
    if (elements !== null) {
      elements.forEach(item => {
        item.classList.remove("show")
      })
    }
    elements = document.querySelectorAll("div.form-multi-select input")
    if (elements !== null) {
      elements.forEach(item => item.value = '')
    }
  }
  },
}
</script>

<style scoped>

</style>
