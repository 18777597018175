<template>

  <UserActionsBar
    @barAction="actionClick"
    :selected-items-count="this.selectedItems.length"
  />

  <BaseTable
    :response-data="responseData"
    @selectRow="selectRow"
    :add-checkboxes="true"
    :is-loading="isLoading"
  />
  <EditRoleModal
    :is-window-visible="visibleAddUser"
    @close="this.visibleAddUser = false"
    @confirm="addFunction"
    :data="selectedItems[0]"
  />

</template>

<script>
import { ref } from 'vue'
import BaseTable from "./BaseTable";
import {
  addUserRole,
  deleteUserRole,
  editUserRole
} from "../../../services/api";
import EditRoleModal from "./EditRoleModal";
import UserActionsBar from "./action-bar/UserActionsBar";
import {useStore} from "vuex";
export default {
  name: 'RolesTable',
  components: {UserActionsBar, EditRoleModal, BaseTable },
  data() {
    return {
      isLoading: false,
      visibleAddUser: false,
      visibleEditUserIsNew: false,
      visibleEditPassword: false,
      selectedItems: []
    }
  },
  setup() {
    const store = useStore()
    let responseData = ref({})
    store.dispatch("loadUserRoles").then(r=>responseData.value = r)
    return {
      responseData
    }
  },
  methods: {
    loadUserRoles() {
      this.selectedItems = []

      this.$store.dispatch("loadUserRoles").then(response => {
        this.responseData = response
        this.isLoading = false
      })
    },
    addFunction(data) {
      this.isLoading = true
      if (data.ID) {
        editUserRole(data).then(
          r => {
            if (r.data[0].meta.success) {
              this.$toast.success(r.data[0].result.message)
            } else {
              this.$toast.error(r.data[0].result.message)
            }
            this.loadUserRoles()
          }
        )
      } else {
        addUserRole(data).then(
          r => {
            if (r.data[0].meta.success) {
              this.$toast.success(r.data[0].result.message)
            } else {
              this.$toast.error(r.data[0].result.message)
            }
            this.loadUserRoles()
          }
        )
      }
    },
    selectRow(row) {
      if (this.selectedItems.filter(item => { return item.ID === row.ID }).length > 0) {
        this.selectedItems = this.selectedItems.filter(item => { return item.ID !== row.ID })
      } else {
        this.selectedItems.push(row)
      }
    },
    actionClick(index) {
      switch (index) {
        case 'add':
          this.showAddUser(true)
          break
        case 'edit':
          this.showAddUser(false)
          break
        case 'delete':
          let ids = this.selectedItems.map( item => { return item.ID} )
          this.selectedItems = []
          deleteUserRole({ID: ids}).then(
            r => this.loadUserRoles()
          )
          break
      }
    },
    showAddUser(isNew) {
      this.visibleAddUser = true
      this.visibleEditUserIsNew = isNew
    }
  }
}
</script>
