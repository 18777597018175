<template>
  <div>
    <div v-if="isLoading"
         style="position: absolute; width: 100%; height: 100%; background-color: rgba(241,239,239,0.74)">
      <CSpinner style="position: absolute;  top: 50%; left: 50%;"/>
    </div>
    <CTable bordered small striped hover>
      <CTableHead color="dark">
        <CTableRow v-if="columns.row1.length > 0">
          <CTableHeaderCell scope="col" rowspan="0" v-if="addCheckboxes"/>

          <CTableHeaderCell scope="col" rowspan="0"></CTableHeaderCell>
          <CTableHeaderCell v-for="(item, index) in columns.row1"
                            :rowspan="columns.row2[index] === ''?0:1"
                            :colspan="item.count"
          >
            {{ item.label }}
          </CTableHeaderCell>
        </CTableRow>
        <CTableRow>
          <CTableHeaderCell scope="col" rowspan="0" v-if="addCheckboxes"/>

          <CTableHeaderCell v-for="(item) in columns.row2.filter(item => item !=='')">{{ item }}</CTableHeaderCell>

        </CTableRow>
      </CTableHead>
      <CTableBody>
        <CTableRow v-for="(row) in items">
          <CTableHeaderCell scope="row" v-if="addCheckboxes">
            <CFormCheck :checked="isRowChecked(row)" @change="selectRow(row)"/>
          </CTableHeaderCell>

          <CTableDataCell v-for="(item) in columns.keys">{{ row[item] }}</CTableDataCell>
        </CTableRow>
      </CTableBody>
    </CTable>
  </div>
</template>

<script>
export default {
  name: "BaseTable",
  emits: ['selectRow'],
  props: {
    isLoading: false,
    addCheckboxes: {type: Boolean, default: false},
    responseData: {}
  },
  data() {
    return {
      selectedItems: [],
      columns: {
        row1:[],
        row2:[]
      },
      items: Array
    }
  },
  methods: {
    selectRow(row) {
      if (this.selectedItems.indexOf(row.ID) >= 0) {
        this.selectedItems = this.selectedItems.filter(item => item !== row.ID)
      } else {
        this.selectedItems.push(row.ID)
      }
      this.$emit('selectRow', row)
    },
    isRowChecked(row) {
      return this.selectedItems.indexOf(row.ID) >= 0
    }
  },

  watch: {
    responseData: function(val) {
      this.items = val.data[0].result.values

      let columnsObj = {
        keys: Object.keys(val.data[0].result.header),
        row1: [],
        row2: Object.values(val.data[0].result.header)
      }
      if (columnsObj.row2.filter(item => {
        return item.indexOf("::") >= 0
      }).length > 0) {
        columnsObj.row1 = columnsObj.row2.map(item => {
          if (item.indexOf("::") >= 0) {
            return item.split("::")[0]
          } else {
            return item
          }
        })
        columnsObj.row2 = columnsObj.row2.map(item => {
          if (item.indexOf("::") >= 0) {
            return item.split("::")[1]
          } else {
            return ""
          }
        })
      }

      columnsObj.row1 = columnsObj.row1.map((item, index, self) => {
        return {
          label: item,
          count: self.filter(selfItem => selfItem === item).length
        }

      })

      var resArr = [];
      columnsObj.row1.forEach(function (item) {
        var i = resArr.findIndex(x => (x.label === item.label));
        if (i <= -1) {
          resArr.push(item);
        }
        return null;
      });
      columnsObj.row1 = resArr

      this.columns = columnsObj
      // return response
    },
    isLoading: function (val) {
      if (val) {
        this.selectedItems = []
      }
    }
  }
}
</script>

<style scoped>

</style>
