<template>

  <UserActionsBar
    :availableActions="['addUser','edit', 'delete']"
    @barAction="actionClick"
    :selected-items-count="this.selectedItems.length"
  />


  <BaseTable
    :response-data="responseData"
    @selectRow="selectRow"
    :add-checkboxes="true"
    :is-loading="isLoading"
  />

  <EditUserModal
    :is-window-visible="visibleAddUser"
    @close="this.visibleAddUser = false"
    @confirm="addUser"
    :data="selectedItems[0]"
  />

<!--  <FormModal-->
<!--    :is-window-visible = "visibleAddUser"-->
<!--    @close="this.visibleAddUser = false"-->
<!--    :fields="fields"-->
<!--  />-->
</template>

<script>
import { ref } from 'vue'
import {addUser, deleteUser, editUser, getEcup, getUsers} from "../../../services/api";
import EditUserModal from "./EditUserModal";
import UserActionsBar from "./action-bar/UserActionsBar";
import FormModal from "./FormModal";
import BaseTable from "./BaseTable";
export default {
  name: 'UsersTable',
  components: {FormModal, UserActionsBar, EditUserModal, BaseTable },
  data() {
    return {
      isLoading: false,

      visibleAddUser: false,
      visibleEditUserIsNew: false,
      selectedItems: [],
    }
  },
  setup() {
    let responseData = ref({})
    getUsers().then(r=>responseData.value = r)
    return {
      responseData
    }
  },
  computed: {
    passwordC() {
      console.log(this.password)
      return this.password
    }
  },
  methods: {
    loadUsers() {
      this.selectedItems = []
      getUsers().then(response => {
        this.responseData = response
        this.isLoading = false
      })
    },
    addUser(data) {
      this.isLoading = true
      if (data.ID) {
        editUser(data).then(
          r => {
            if (r.data[0].meta.success) {
              this.$toast.success(r.data[0].result.message)
            } else {
              this.$toast.error(r.data[0].result.message)
            }
            this.loadUsers()

          }
        )
      } else {
        addUser(data).then(
          r => {
            if (r.data[0].meta.success) {
              this.$toast.success(r.data[0].result.message)
            } else {
              this.$toast.error(r.data[0].result.message)
            }
            this.loadUsers()
          }
        )
      }
    },
    selectRow(row) {
      if (this.selectedItems.filter(item => { return item.ID === row.ID }).length > 0) {
        this.selectedItems = this.selectedItems.filter(item => { return item.ID !== row.ID })
      } else {
        this.selectedItems.push(row)
      }
    },
    actionClick(index) {
      switch (index) {
        case 'addUser':
          this.showAddUser()
          break
        case 'edit':
          this.showEditUser()
          break
        case 'delete':
          let ids = this.selectedItems.map( item => { return item.ID} )
          this.selectedItems = []
          deleteUser({ID: ids}).then(
            r => this.loadUsers()
          )
          break
      }
    },
    showAddUser() {
      this.visibleAddUser = true
      this.visibleEditUserIsNew = true
    },
    showEditUser() {
      this.visibleAddUser = true
      this.visibleEditUserIsNew = false
    },
  },
}
</script>

<style scoped>
 .nav-search {
   margin-left: auto;
 }
 .nav-search div {
   top: 0.3em;
   right: 0.5em;
 }
</style>
