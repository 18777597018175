<template>
  <BaseModal alignment="center" size="lg" :is-visible="visible" @close="visible = false" backdrop="static">
    <CModalHeader>
      <CModalTitle v-if="isNew">Новая запись</CModalTitle>
      <CModalTitle v-else>Редактирование</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <CForm>
        <CRow class="mb-3">
          <CFormLabel for="Controller" class="col-sm-2 col-form-label">Контроллер:*</CFormLabel>
          <CCol sm="10">
            <CFormInput
              :value="form.Controller"
              @input="form.Controller = $event.target.value"
              id="Controller"
            />
          </CCol>
        </CRow>
        <CRow class="mb-3">
          <CFormLabel for="Action" class="col-sm-2 col-form-label">Функция:*</CFormLabel>
          <CCol sm="10">
            <CFormInput
              :value="form.Action"
              @input="form.Action = $event.target.value"
              id="Action"
            />
          </CCol>
        </CRow>
        <CRow class="mb-3">
          <CFormLabel for="Desc" class="col-sm-2 col-form-label">Описание:</CFormLabel>
          <CCol sm="10">
            <CFormTextarea
              :value="form.Desc"
              @input="form.Desc = $event.target.value"
              id="Desc"
              rows="3"></CFormTextarea>
          </CCol>
        </CRow>
      </CForm>
    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="() => { this.visible = false }">
        Отменить
      </CButton>
      <CButton color="primary" @click="confirm">Сохранить</CButton>
    </CModalFooter>
  </BaseModal>
</template>

<script>
import BaseModal from "../../widgets/BaseModal";
export default {
  name: "EditFunctionModal",
  components: {BaseModal},
  emits: ['close', 'confirm'],
  props: {
    data: {},
    isWindowVisible: {type: Boolean, default: false},
  },
  data() {
    return {
      form: {
        Controller: '',
        Action: '',
        Desc: []
      },
      visible: this.isWindowVisible
    }
  },
  methods: {
    confirm() {
      this.$emit('confirm', this.form)
      this.visible = false
    },

  },
  computed: {
    isNew() {
      return !(this.data && this.data.ID)
    }
  },
  watch: {
    visible: function(val) {
      if (!val) {
        this.form.Controller = ''
        this.form.Action = ''
        this.form.Desc = ''
        this.$emit('close')
      }
    },
    isWindowVisible: function(val) {
      this.visible = val
      if (val && this.data) {
        this.form = { ... this.data}
      }
    }
  }
}
</script>

<style scoped>

</style>
