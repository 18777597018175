<template>
    <UserActionsBar
      @barAction="actionClick"
      :selected-items-count="this.selectedItems.length"
    />
    <BaseTable
      :response-data="responseData"
      @selectRow="selectRow"
      :add-checkboxes="true"
      :is-loading="isLoading"
    />
    <EditFunctionModal
      :is-window-visible="visibleEditWindow"
      @close="this.visibleEditWindow = false"
      @confirm="addFunction"
      :data="selectedItems[0]"
    />
</template>

<script>
import {ref} from 'vue'
import {addUserFunction, deleteUserFunction, editUserFunction} from "../../../services/api";
import UserActionsBar from "./action-bar/UserActionsBar";
import EditFunctionModal from "./EditFunctionModal";
import BaseTable from "./BaseTable";
import {useStore} from "vuex";

export default {
  name: 'FunctionsTable',
  components: {BaseTable, EditFunctionModal, UserActionsBar},
  data() {
    return {
      isLoading: false,
      visibleEditWindow: false,
      visibleEditWindowIsNew: false,
      selectedItems: []
    }
  },
  setup() {
    const store = useStore()
    let responseData = ref({})
    store.dispatch("loadUserFunctions").then(r=>responseData.value = r)
    return {
      responseData
    }
  },
  methods: {
    loadUserFunctions() {
      this.selectedItems = []
      this.$store.dispatch("loadUserFunctions").then(response => {
        this.responseData = response
        this.isLoading = false
      })
    },
    addFunction(data) {
      this.isLoading = true
      if (data.ID) {
        editUserFunction(data).then(
          r => {
            if (r.data[0].meta.success) {
              this.$toast.success(r.data[0].result.message)
            } else {
              this.$toast.error(r.data[0].result.message)
            }
            this.loadUserFunctions()
          }
        )
      } else {
        addUserFunction(data).then(
          r => {
            if (r.data[0].meta.success) {
              this.$toast.success(r.data[0].result.message)
            } else {
              this.$toast.error(r.data[0].result.message)
            }
            this.loadUserFunctions()
          }
        )
      }
    },
    selectRow(row) {
      if (this.selectedItems.filter(item => {
        return item.ID === row.ID
      }).length > 0) {
        this.selectedItems = this.selectedItems.filter(item => {
          return item.ID !== row.ID
        })
      } else {
        this.selectedItems.push(row)
      }
    },
    actionClick(index) {
      switch (index) {
        case "add":
          this.showNew()
          break
        case "edit":
          this.showEdit()
          break
        case "delete":
          this.isLoading = true
          // let ids = this.selectedItems.map( item => { return {"ID": item.ID}} )
          let ids = this.selectedItems.map( item => { return item.ID} )
          this.selectedItems = []
          deleteUserFunction({ID: ids}).then(
            r => this.loadUserFunctions()
          )
          // this.visibleEditPassword = true
          break
      }
    },
    showNew() {
      this.visibleEditWindow = true
      this.visibleEditWindowIsNew = true
    },
    showEdit() {
      this.visibleEditWindow = true
      this.visibleEditWindowIsNew = false
    }
  }
}
</script>
