<template>
  <CCard class="mb-4">
    <CNav class="nav-underline">
      <CNavItem v-for="(tab, index) in tabs" :key="index">
        <CNavLink @click="selectTab(index)" :active="selectedTab === index"> {{ tab.label }} </CNavLink>
      </CNavItem>
    </CNav>
    <UsersTable v-if="selectedTab === 0"/>
    <RolesTable v-if="selectedTab === 1"/>
    <FunctionsTable v-if="selectedTab === 2" />
  </CCard>
</template>

<script>
import UsersTable from "./UsersTable";
import RolesTable from "./RolesTable";
import FunctionsTable from "./FunctionsTable";
export default {
  name: "UsersRoot",
  components: {FunctionsTable, RolesTable, UsersTable},

  data() {
    return {
      selectedTab: 0,
      tabs: [
        {
          label: "Пользователи"
        },
        {
          label: "Роли пользователей"
        },
        {
          label: "Функции"
        },
        // {
        //   label: "Настройка меню"
        // },
      ]
    }
  },
  methods: {
    selectTab(index) {
      this.selectedTab = index
    }
  },
}
</script>

<style scoped>

</style>
