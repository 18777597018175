<template>
  <BaseModal alignment="center" size="lg" :is-visible="visible"  @close="visible = false" backdrop="static">
    <CModalHeader>
      <CModalTitle v-if="isNew">Новая запись</CModalTitle>
      <CModalTitle v-else>Редактирование</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <CForm>
        <CRow class="mb-3">
          <CFormLabel for="RoleName" class="col-sm-2 col-form-label" >Наименование:*</CFormLabel>
          <CCol sm="10">
            <CFormInput
              :value="form.RoleName"
              @input="form.RoleName = $event.target.value"
              id="RoleName"
              required
            />
          </CCol>
        </CRow>
        <CRow class="mb-3">
          <CFormLabel for="inputPassword3" class="col-sm-2 col-form-label" id="UserFunctions">Функции:*</CFormLabel>
          <CCol sm="10">
            <CMultiSelect
              :options="functions"
              placeholder="Выберите"
              selectAllLabel="Выбрать все"
              @change="functionsChange"
              required
            />
          </CCol>
        </CRow>
<!--        <CRow class="mb-3">-->
<!--          <CFormLabel for="inputPassword3" class="col-sm-2 col-form-label">Меню:*</CFormLabel>-->
<!--          <CCol sm="10">-->
<!--            <CMultiSelect-->
<!--              :options="menus"-->
<!--              placeholder="Выберите"-->
<!--              selectAllLabel="Выбрать все"-->
<!--            />-->
<!--          </CCol>-->
<!--        </CRow>-->
        <CRow class="mb-3">
          <CFormLabel for="Desc" class="col-sm-2 col-form-label">Комментарий:</CFormLabel>
          <CCol sm="10">
            <CFormTextarea
              id="Desc"
              rows="3"
              :value="form.Desc"
              @input="form.Desc = $event.target.value"
            ></CFormTextarea>
          </CCol>
        </CRow>
      </CForm>
    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="() => { this.visible = false }">
        Отменить
      </CButton>
      <CButton color="primary" @click="confirm">Сохранить</CButton>
    </CModalFooter>
  </BaseModal>
</template>

<script>

import BaseModal from "../../widgets/BaseModal";
export default {
  name: "EditRoleModal",
  components: {BaseModal},
  emits: ["close", "confirm"],
  props: {
    data: {},
    isWindowVisible: false,
  },
  data() {
    return {
      form: {
        RoleName: '',
        Desc: '',
        UserFunctions: []
      },
      visible: this.isWindowVisible,
      functions: this.getFunctions(),
      // menus: [
      //   {
      //     value: 0,
      //     text: 'Angular',
      //   },
      //   {
      //     value: 1,
      //     text: 'Bootstrap',
      //   },
      //   {
      //     value: 2,
      //     text: 'React.js',
      //   },
      //   {
      //     value: 3,
      //     text: 'Vue.js',
      //   },
      //   {
      //     label: 'backend',
      //     options: [
      //       {
      //         value: 4,
      //         text: 'Django',
      //       },
      //       {
      //         value: 5,
      //         text: 'Laravel',
      //       },
      //       {
      //         value: 6,
      //         text: 'Node.js',
      //       },
      //     ],
      //   },
      // ],
    }
  },
  methods: {
    functionsChange(items) {
      this.form.UserFunctions = items.map(item => item.value)
    },
    confirm() {
      this.$emit('confirm', this.form)
      this.visible = false
    },
    isFunctionSelected(item) {

      this.form && console.log('isFunctionSelected ', item.ID, this.form.UserFunctions)
      return this.form &&
        (this.form.UserFunctions.indexOf(parseInt(item.ID)) >= 0 || this.form.UserFunctions.indexOf(item.ID) >= 0)
    },
    getFunctions() {
      return this.$store.getters.getUserFunctions.values.map(item=>{ return { value: item.ID, text: item.Desc, selected: this.isFunctionSelected(item)} })
    }
  },
  watch: {
    visible: function(val) {
      if (!val) {
        this.form.RoleName = ''
        this.form.UserFunctions = []
        this.form.Desc = ''
        this.$emit('close')
      }
    },
    isWindowVisible: function(val) {
      this.visible = val
      if (val && this.data) {
        this.form = { ... this.data}
        this.functions = this.getFunctions()
      }
    }
  },
  computed: {
    isNew() {
      return !(this.data && this.data.ID)
    }
  },
}
</script>

<style scoped>

</style>
