<template>
  <CNav class="nav">
    <CNavItem>
      <CFormSelect v-if="isSmcTypeVisible" @change="selectChange">
        <option value="activeSmc">Активные подписки</option>
        <option value="inactiveSmc">Неактивные подписки</option>
        <option value="onetime">Разовые запросы</option>
      </CFormSelect>
    </CNavItem>
    <CNavItem v-for="(action) in actions">
      <CNavLink
        @click="actionClick(action.name)"
        :disabled="isDisabled(action.requireItems)"
        v-if="isVisible(action.name)"
      >
        <CIcon :icon="action.icon" class="me-2" />
        {{ action.label }}
      </CNavLink>
    </CNavItem>
    <CNavItem v-if="isSearchVisible" class="nav-search">
      <CInputGroup size="sm" class="mb-3">
        <CFormInput placeholder="Поиск" aria-label="Поиск"/>
        <CButton @click="clearSearch" type="button" color="secondary" variant="outline">
          <CIcon icon="cil-filter-x" />
        </CButton>
        <CButton  type="button" color="primary" variant="outline">
          <CIcon icon="cil-search" />
        </CButton>
      </CInputGroup>
    </CNavItem>
  </CNav>
  <ConfirmModal
    :is-window-visible="deleteWindowVisible"
    @close="deleteWindowVisible = false"
    @confirm="confirmDelete"
  />
</template>

<script>
import ConfirmModal from "./ConfirmModal";
export default {
  name: "UserActionsBar",
  components: {ConfirmModal},
  emits: ["barAction"],
  props: {
    isSmcTypeVisible: { type: Boolean, default: false },
    isSearchVisible: { type: Boolean, default: true },
    availableActions: {
      type: Array,
      default: ['add','edit', 'delete']
    },
    selectedItemsCount: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      deleteWindowVisible: false,
      searchText: '',
      actions: [
        {
          label: "Добавить",
          icon: "cil-user-plus",
          requireItems: 0,
          name:"addUser"
        },
        {
          label: "Добавить",
          icon: "cil-plus",
          requireItems: 0,
          name:"add"
        },
        {
          label: "Изменить",
          icon: "cil-pencil",
          requireItems: 1,
          name:"edit"
        },
        {
          label: "Сменить пароль",
          icon: "cil-lock-locked",
          requireItems: 1,
          name: "editPassword"
        },
        {
          label: "Удалить",
          icon: "cil-trash",
          requireItems: Number.MAX_SAFE_INTEGER,
          name:"delete"
        },
        {
          label: "Скрыть",
          icon: "cil-flip",
          requireItems: Number.MAX_SAFE_INTEGER,
          name:"hide"
        },
        {
          label: "Открыть в новой вкладке",
          icon: "cil-browser",
          requireItems: 1,
          name:"openInBrowser"
        },
        {
          label: "Отписать",
          icon: "cil-trash",
          requireItems: Number.MAX_SAFE_INTEGER,
          name:"unsubscribe"
        },
      ]
    }
  },
  methods: {
    selectChange(event) {
      this.actionClick(event.target.value)
    },
    actionClick(name) {
      if (name === 'delete') {
        this.deleteWindowVisible = true
      } else {
        this.$emit('barAction', name)
      }
    },
    confirmDelete() {
      this.deleteWindowVisible = false
      this.$emit('barAction', 'delete')
    },
    isVisible(name) {
      return this.availableActions.indexOf(name) >= 0
    },
    clearSearch() {
      this.searchText = ''
    },
    isDisabled(requireItems) {
      if (requireItems === 0) {
        return false
      }
      if (this.selectedItemsCount > 0) {
        if (requireItems === 1 && this.selectedItemsCount === 1) {
          return false
        }
        if (requireItems === Number.MAX_SAFE_INTEGER) {
          return false
        }
        return true
      } else {
        return true
      }
    }
  },
}
</script>

<style scoped>

</style>
