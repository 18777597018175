<template>

  <BaseModal alignment="center" size="lg" :is-visible="visible" @close="this.visible = false">
    <CModalHeader>
      <CModalTitle v-if="isNew">Новая запись</CModalTitle>
      <CModalTitle v-else>Редактирование</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <CForm>
        <CRow class="mb-3">
          <CFormLabel for="Login" class="col-sm-2 col-form-label">Имя:*</CFormLabel>
          <CCol sm="10">
            <CFormInput
              :value="form.UserName"
              @input="form.UserName = $event.target.value"
              id="Login"/>
          </CCol>
        </CRow>
        <CRow class="mb-3">
          <CFormLabel for="Password" class="col-sm-2 col-form-label">Логин:*</CFormLabel>
          <CCol sm="10">
            <CFormInput type="login"
                        :value="form.Login"
                        @input="form.Login = $event.target.value"
                        id="Password"/>
          </CCol>
        </CRow>
        <CRow class="mb-3">
          <CFormLabel for="Password" class="col-sm-2 col-form-label">Пароль:*</CFormLabel>
          <CCol sm="10">
            <CInputGroup>
              <CFormInput
                :value="form.Password"
                @input="form.Password = $event.target.value"
                type="login" id="Password"/>
              <CButton type="button" color="primary" @click="generatePassword">Новый пароль</CButton>
            </CInputGroup>
          </CCol>
        </CRow>
        <!--        <CRow class="mb-4">-->
        <!--          <CFormLabel for="inputPassword3" class="col-sm-3 col-form-label">Шифровать:</CFormLabel>-->
        <!--          <CCol sm="10">-->
        <!--            <CFormCheck inline checked type="radio" name="inlineRadioOptions" id="inlineCheckbox1" value="option1"-->
        <!--                        label="Да"/>-->
        <!--            <CFormCheck inline type="radio" name="inlineRadioOptions" id="inlineCheckbox2" value="option2" label="Нет"/>-->
        <!--          </CCol>-->
        <!--        </CRow>-->

        <CRow class="mb-3">
          <CFormLabel for="inputPassword3" class="col-sm-2 col-form-label">Роли:*</CFormLabel>
          <CCol sm="10">
            <CMultiSelect
              :multiple="false"
              :options="roleOptions"
              placeholder="Выберите"
              selectAllLabel="Выбрать все"
              @change="rolesChange"
            />
          </CCol>
        </CRow>
        <CRow class="mb-3">
          <CFormLabel for="Email" class="col-sm-2 col-form-label">Email*:</CFormLabel>
          <CCol sm="10">
            <CFormInput
              :value="form.Email"
              @input="form.Email = $event.target.value"
              type="email"
              id="Email"/>
          </CCol>
        </CRow>
        <!--        <CRow class="mb-3">-->
        <!--          <CFormLabel for="inputPassword3" class="col-sm-2 col-form-label">IP:</CFormLabel>-->
        <!--          <CCol sm="10">-->
        <!--            <CFormInput type="email" id=""/>-->
        <!--            <span-->
        <!--              class="small">Примечание: при указании IP доступ к Synergy будет доступен только с указаного адреса</span>-->
        <!--          </CCol>-->
        <!--        </CRow>-->
        <!--        <CRow class="mb-3">-->
        <!--          <CFormLabel for="inputPassword3" class="col-sm-2 col-form-label">Телефон:</CFormLabel>-->
        <!--          <CCol sm="10">-->
        <!--            <CFormInput type="email" id=""/>-->
        <!--          </CCol>-->
        <!--        </CRow>-->
        <!--        <CRow class="mb-3">-->
        <!--          <CFormLabel for="inputPassword3" class="col-sm-2 col-form-label">Веб интерфейс:</CFormLabel>-->
        <!--          <CCol sm="10">-->
        <!--            <CFormInput disabled type="email" id=""/>-->
        <!--          </CCol>-->
        <!--        </CRow>-->
        <!--        <CRow class="mb-3">-->
        <!--          <CFormLabel for="inputPassword3" class="col-sm-2 col-form-label">Комментарий:</CFormLabel>-->
        <!--          <CCol sm="10">-->
        <!--            <CFormTextarea id="exampleFormControlTextarea1" rows="3"></CFormTextarea>-->
        <!--          </CCol>-->
        <!--        </CRow>-->
      </CForm>
    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="() => { this.visible = false }">
        Отменить
      </CButton>
      <CButton color="primary" @click="confirm">Сохранить</CButton>
    </CModalFooter>
  </BaseModal>

<!--  <CModal alignment="center" size="lg" :visible="visible"  @close="visible = false" backdrop="static">-->
<!--    <CModalHeader>-->
<!--      <CModalTitle v-if="isNew">Новая запись</CModalTitle>-->
<!--      <CModalTitle v-else>Редактирование</CModalTitle>-->
<!--    </CModalHeader>-->
<!--    <CModalBody>-->
<!--      <CForm>-->
<!--        <CRow class="mb-3">-->
<!--          <CFormLabel for="Login" class="col-sm-2 col-form-label">Имя:*</CFormLabel>-->
<!--          <CCol sm="10">-->
<!--            <CFormInput-->
<!--              :value="form.UserName"-->
<!--              @input="form.UserName = $event.target.value"-->
<!--              id="Login"/>-->
<!--          </CCol>-->
<!--        </CRow>-->
<!--        <CRow class="mb-3">-->
<!--          <CFormLabel for="Password" class="col-sm-2 col-form-label">Логин:*</CFormLabel>-->
<!--          <CCol sm="10">-->
<!--            <CFormInput type="login"-->
<!--                        :value="form.Login"-->
<!--                        @input="form.Login = $event.target.value"-->
<!--                        id="Password"/>-->
<!--          </CCol>-->
<!--        </CRow>-->
<!--        <CRow class="mb-3">-->
<!--          <CFormLabel for="Password" class="col-sm-2 col-form-label">Пароль:*</CFormLabel>-->
<!--          <CCol sm="10">-->
<!--            <CInputGroup>-->
<!--              <CFormInput-->
<!--                :value="form.Password"-->
<!--                @input="form.Password = $event.target.value"-->
<!--                type="login" id="Password"/>-->
<!--              <CButton type="button" color="primary" @click="generatePassword">Новый пароль</CButton>-->
<!--            </CInputGroup>-->
<!--          </CCol>-->
<!--        </CRow>-->
<!--&lt;!&ndash;        <CRow class="mb-4">&ndash;&gt;-->
<!--&lt;!&ndash;          <CFormLabel for="inputPassword3" class="col-sm-3 col-form-label">Шифровать:</CFormLabel>&ndash;&gt;-->
<!--&lt;!&ndash;          <CCol sm="10">&ndash;&gt;-->
<!--&lt;!&ndash;            <CFormCheck inline checked type="radio" name="inlineRadioOptions" id="inlineCheckbox1" value="option1"&ndash;&gt;-->
<!--&lt;!&ndash;                        label="Да"/>&ndash;&gt;-->
<!--&lt;!&ndash;            <CFormCheck inline type="radio" name="inlineRadioOptions" id="inlineCheckbox2" value="option2" label="Нет"/>&ndash;&gt;-->
<!--&lt;!&ndash;          </CCol>&ndash;&gt;-->
<!--&lt;!&ndash;        </CRow>&ndash;&gt;-->

<!--        <CRow class="mb-3">-->
<!--          <CFormLabel for="inputPassword3" class="col-sm-2 col-form-label">Роли:*</CFormLabel>-->
<!--          <CCol sm="10">-->
<!--            <CMultiSelect-->
<!--              :multiple="false"-->
<!--              :options="roleOptions"-->
<!--              placeholder="Выберите"-->
<!--              selectAllLabel="Выбрать все"-->
<!--              @change="rolesChange"-->
<!--            />-->
<!--          </CCol>-->
<!--        </CRow>-->
<!--        <CRow class="mb-3">-->
<!--          <CFormLabel for="Email" class="col-sm-2 col-form-label">Email*:</CFormLabel>-->
<!--          <CCol sm="10">-->
<!--            <CFormInput-->
<!--              :value="form.Email"-->
<!--              @input="form.Email = $event.target.value"-->
<!--              type="email"-->
<!--              id="Email"/>-->
<!--          </CCol>-->
<!--        </CRow>-->
<!--&lt;!&ndash;        <CRow class="mb-3">&ndash;&gt;-->
<!--&lt;!&ndash;          <CFormLabel for="inputPassword3" class="col-sm-2 col-form-label">IP:</CFormLabel>&ndash;&gt;-->
<!--&lt;!&ndash;          <CCol sm="10">&ndash;&gt;-->
<!--&lt;!&ndash;            <CFormInput type="email" id=""/>&ndash;&gt;-->
<!--&lt;!&ndash;            <span&ndash;&gt;-->
<!--&lt;!&ndash;              class="small">Примечание: при указании IP доступ к Synergy будет доступен только с указаного адреса</span>&ndash;&gt;-->
<!--&lt;!&ndash;          </CCol>&ndash;&gt;-->
<!--&lt;!&ndash;        </CRow>&ndash;&gt;-->
<!--&lt;!&ndash;        <CRow class="mb-3">&ndash;&gt;-->
<!--&lt;!&ndash;          <CFormLabel for="inputPassword3" class="col-sm-2 col-form-label">Телефон:</CFormLabel>&ndash;&gt;-->
<!--&lt;!&ndash;          <CCol sm="10">&ndash;&gt;-->
<!--&lt;!&ndash;            <CFormInput type="email" id=""/>&ndash;&gt;-->
<!--&lt;!&ndash;          </CCol>&ndash;&gt;-->
<!--&lt;!&ndash;        </CRow>&ndash;&gt;-->
<!--&lt;!&ndash;        <CRow class="mb-3">&ndash;&gt;-->
<!--&lt;!&ndash;          <CFormLabel for="inputPassword3" class="col-sm-2 col-form-label">Веб интерфейс:</CFormLabel>&ndash;&gt;-->
<!--&lt;!&ndash;          <CCol sm="10">&ndash;&gt;-->
<!--&lt;!&ndash;            <CFormInput disabled type="email" id=""/>&ndash;&gt;-->
<!--&lt;!&ndash;          </CCol>&ndash;&gt;-->
<!--&lt;!&ndash;        </CRow>&ndash;&gt;-->
<!--&lt;!&ndash;        <CRow class="mb-3">&ndash;&gt;-->
<!--&lt;!&ndash;          <CFormLabel for="inputPassword3" class="col-sm-2 col-form-label">Комментарий:</CFormLabel>&ndash;&gt;-->
<!--&lt;!&ndash;          <CCol sm="10">&ndash;&gt;-->
<!--&lt;!&ndash;            <CFormTextarea id="exampleFormControlTextarea1" rows="3"></CFormTextarea>&ndash;&gt;-->
<!--&lt;!&ndash;          </CCol>&ndash;&gt;-->
<!--&lt;!&ndash;        </CRow>&ndash;&gt;-->
<!--      </CForm>-->
<!--    </CModalBody>-->
<!--    <CModalFooter>-->
<!--      <CButton color="secondary" @click="() => { this.visible = false }">-->
<!--        Отменить-->
<!--      </CButton>-->
<!--      <CButton color="primary" @click="confirm">Сохранить</CButton>-->
<!--    </CModalFooter>-->
<!--  </CModal>-->
</template>

<script>
import BaseModal from "../../widgets/BaseModal";
export default {
  name: "AddUserModal",
  components: {BaseModal},
  emits: ['close', 'confirm'],
  props: {
    data: {},
    isWindowVisible: false,
  },
  data() {
    return {
      form: {
        Password: '',
        Login: '',
        UserName: '',
        Role: [],
        Email: '',
        Active: true,
        UserThemeID: ''
      },
      visible: this.isWindowVisible,
      password: '',
      roleOptions: this.getRoles(),
    }
  },
  methods: {
    confirm() {
      this.$emit('confirm', this.form)
      this.visible = false
    },
    rolesChange(items) {
      if (items[0]) {
        this.form.Role = items[0].value
      } else {
        this.form.Role = ''
      }
    },
    getRoles() {

      return this.$store.getters.getUserRoles.values.map(item => {
        const selected = this.form && ''+item.ID === ''+this.form.Role
        return {value: item.ID, text: item.Desc, selected: selected}
      })
    },
    generatePassword() {
      {
        let length = 8
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
        }
        this.form.Password = result
      }
    }
  },
  watch: {
    visible: function (val) {
      if (!val) {
        this.form.Password = ''
        this.form.UserName = ''
        this.form.Email = ''
        this.form.Role = []
        this.form.Login = ''
        this.$emit('close')
      }
    },
    isWindowVisible: function (val) {
      this.visible = val
      if (val && this.data) {
        this.form = { ... this.data}
        this.roleOptions = this.getRoles()
      }
    }
  },
  computed: {
    isNew() {
      return !(this.data && this.data.ID)
    }
  },
}
</script>

<style scoped>

</style>
